// src/components/About.tsx
import React from 'react';
import '../assets/styles/About.css';

const About: React.FC = () => (
  <section className="about-container">
    <h2>About Me</h2>
    <p>
    Hi, I’m Darragh, a dedicated web developer from Missoula, Montana. I earned my BS in Computer Science from the University of Denver while competing as a captain on the varsity Division One swim team. Since 2018, I have been passionately working in the field, committed to delivering high-quality products. I strongly believe in kindness and making a difference through my work. I thrive in collaborative environments and enjoy having conversations with community members, always eager to learn new technologies at hyper speed. My journey in web development has been fueled by a continuous desire to learn and adapt to the ever-evolving landscape of technology.
    </p>
  </section>
);

export default About;