// src/components/Skills.tsx
import React from 'react';
import '../assets/styles/Skills.css';

const Skills: React.FC = () => (
  <section className="skills-container">
    <h2>Skills</h2>
    <ul>
      <li>JavaScript</li>
      <li>TypeScript</li>
      <li>React</li>
      <li>Node.js</li>
      <li>HTML & CSS</li>
      <li>Git & GitHub</li>
      <li>Redux</li>
      <li>Python (Django, Flask)</li>
      <li>RESTful APIs</li>
      <li>FastAPI</li>
      <li>SQL</li>
      <li>PostgreSQL</li>
      <li>OAuth</li>
      <li>JWT</li>
      <li>Express.js</li>
      <li>Nginx</li>
      <li>AWS</li>
      <li>Netlify</li>
      <li>Package Management</li>
      <li>Babel</li>
      <li>Caching</li>
      <li>HTTPS</li>
      <li>Problem Solving</li>
      <li>Collaboration</li>
      <li>Agile/Scrum Methods</li>
      <li>Project Management Tools</li>
      <li>Communication</li>
    </ul>
  </section>
);

export default Skills;